import email from './email.png';
import dribble from './dribbble.png';
import plus from './plus.svg';
import edit from './edit.svg';
import remove from './delete.svg';
import leftChevron from './left-chevron.svg';

export const commonIcons = {
    email,
    dribble,
    plus,
    edit,
    remove,
    leftChevron,
};
